import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const KosciolKuflewPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Kościół w Kuflewie" />
      <ReturnToPrevPage to={'/gminy/mrozy'}>
        Materiały dotyczące gminy Mrozy
      </ReturnToPrevPage>
      <Header1>Kościół w Kuflewie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={896583059} />
        <p>
          Powołana do życia parafia otrzymała jako wezwanie św. Marcina i św.
          Mikołaja. Postanowienie to nawiązywało do postaci głównych jej
          fundatorów. Należeli do nich jeden z właścicieli miejscowych gruntów
          Marcina Oborskiego oraz jego nieżyjącego wtedy już ojca Mikołaja.
          Zgodnie z ustaleniami historyków istniejący od 1515 roku drewniany
          kościół uległ pożarowi. W 1542 roku nową świątynię, ufundowaną przez
          Wernera Oborskiego, poświęcił biskup poznański Sebastian Branicki. Nie
          minęło sto lat, gdy ówcześni mieszkańcy Kuflewa stali się świadkami
          budowy i poświęcenia nowego kościoła.
        </p>
        <p>
          Obok dotychczasowego, znajdującego się w bardzo złym stanie,
          postawiono go staraniem wdowy po Janie Oborskim. Poświęcenia dokonał
          15 grudnia 1629 roku biskup poznański Maciej Łubieński. Nowe miejsce
          kultu religijnego otrzymało wezwanie świętych Stanisława Marcina i
          Marii Magdaleny. Druga połowa osiemnastego wieku przyniosła tymczasem
          wybudowanie na obszarze miejscowości czwartego w jej dziejach
          kościoła. Stało się to możliwe dzięki działaniom podjętym przez
          właściciela Kuflewa Rafała Ludwika Skarbka oraz proboszcza parafii
          Franciszka Kosowskiego. Świątynia ta pojawiła się w kuflewskim
          krajobrazie w 1759 roku. W następnym stuleciu drewniany, sakralny,
          obiekt poddano remontowi i rozbudowie. Uwagę w nim przykuwały blaszana
          wieżyczka z kopulastym hełmem, rokokowy ołtarz, żyrandol oraz
          chrzcielnica z miedzianą misą chrzcielną. W niewyjaśnionych dotąd
          okolicznościach kościół ten spłonął w nocy z 7 na 8 marca 1991 roku.
          Bardzo szybko podjęto starania mające na celu zbudowanie nowej, piątej
          w historii miejscowości, świątyni. W jego projekcie wykorzystano
          architekturę zniszczonego obiektu, a także plan kaplicy przewidzianej
          do zrealizowania w Guzewie.
        </p>
        <p>
          Konsekracja kościoła nastąpiła 29 maja 1994 roku. Dokonał jej ówczesny
          ordynariusz diecezji warszawsko-praskiej ksiądz biskup Kazimierz
          Romaniuk. Warto dodać, że powojennymi proboszczami parafii byli księża
          Aleksander Stypułkowski (1935 – 1946), Wiktor Łubiński (1946 – 1948),
          Józef Barszczewski (1948 – 1949), Stefan Opiela (1949 – 1968),
          Władysław Furmański (1968 – 1983), Janusz Kuśmierczyk (1983 – 1995),
          Szczepan Dmochowski (1995 – 1999), Bogdan Sałański (1999 – 2004),
          Anastazy Witold Koć (2004 – 2011). Od 28 sierpnia 2011 roku godność tę
          pełni ks. Krzysztof Kulbat. Zauważmy, że w otoczeniu kościoła
          parafialnego znajduje się kilka ciekawych obiektów. Należą do nich:
          dzwonnica, figura św. Jana Nepomucena, pomnik św. Antoniego,
          organistówka i plebania. Ostatnie z wymienionych miejsc ujrzało
          światło dzienne w 1904 roku. Zadanie jej postawienia zrealizował
          ksiądz Mikołaj Bojanek. Parterowy budynek z dwoma symetrycznie
          rozłożonymi dwukondygnacyjnymi skrzydłami o różnej wysokości znajduje
          się na zachód od kościoła. Z początku dwudziestego wieku pochodzi też
          organistówka. Została ona wybudowana na planie prostokąta, na ceglanej
          podmurówce.
        </p>
        <p>
          Co ciekawe jej dach pokryty jest coraz rzadziej spotykanym gontem.
          Dziewiętnaste stulecie przyniosło natomiast pojawienie się na terenie
          kościoła ponad dziewięciometrowego pomnika św. Antoniego. Jasną
          figurkę przedstawiającą świętego wykonano z włoskiego marmuru. Ceglana
          kolumna obiektu wznosi się mając podstawę w granitowych stopniach.
          Zabytek wybudowano w 1864 roku. Ufundowała go Weronika z Łąckich
          Dąbrowska, która tym faktem wyraziła podziękowanie za odzyskanie
          skonfiskowany dóbr Kuflewa. Druga z figur znajdujących się w otoczeniu
          kościoła przedstawia św. Jana Nepomucena. Powstanie przykładu
          barokowej twórczości datuje się na lata 1760 – 1775. Jego autorstwo
          przypisywane jest pochodzącemu z krajów niemieckich polskiemu
          rzeźbiarzowi Janowi Chryzostomowi Redlerowi. Dodajmy, że do
          sporządzenia tego upamiętnienia świętego wykorzystano piaskowiec. Z
          kolei posiadająca architektoniczne korzenie w baroku dzwonnica została
          wybudowana z cegły. Zaprojektowana na planie trójkąta charakteryzuje
          się ona posiadaniem w górnej części półkolistych przeźroczy. Na
          obszarze kuflewskiej parafii można ponadto obejrzeć kilka innych,
          interesujących, obiektów kultu religijnego. Zaliczyć do nich można
          kapliczkę znajdującą się przy drodze do Huty Kuflewskiej oraz
          analogiczne obiekty zlokalizowane w Podskwarnem i Liwcu. Uwagę osób
          zamieszkujących lub odwiedzających ten obszar zwracają również krzyże
          które można zobaczyć w Kuflewie, Małej Wsi i Podcierniu.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/mrozy/kuflew-kosciol/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/mrozy/kuflew-kosciol/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default KosciolKuflewPage
